import useVerify from "./verify-hook";
import { Box, Button, TextField, Typography } from "@mui/material";

import { imageFallback } from "../../utils";
import { FullScreenLoader } from "../../components";

const VerifyUser = () => {
  const {
    imgUrl,
    loading,
    errorsVerify,
    registerVerify,
    onSubmitVerify,
    handleSubmitVerify,
  } = useVerify();

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      sx={{
        p: 2,
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
      }}
      onSubmit={handleSubmitVerify(onSubmitVerify)}
    >
      <Box sx={{ mb: 3, textAlign: "center" }}>
        <Box
          maxHeight="100px"
          sx={{
            objectFit:'contain',
            maxWidth:"100%",
          }}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      <Typography variant="h6" component="div" sx={{ color: "text.secondary" }}>
        Please enter your details
      </Typography>
      <TextField
        required
        sx={{ mt: 2 }}
        variant="outlined"
        label="Reservation ID or Last Name"
        {...registerVerify("name", {
          maxLength: 30,
          required: "Please enter Last Name or Reservationd ID!",
        })}
        error={Boolean(errorsVerify.name)}
        helperText={errorsVerify.name?.message}
      />
      <TextField
        required
        sx={{ mt: 2 }}
        variant="outlined"
        label="Room Number"
        {...registerVerify("roomNo", {
          maxLength: 30,
          required: "Please enter the room number!",
        })}
        error={Boolean(errorsVerify.roomNo)}
        helperText={errorsVerify.roomNo?.message}
      />

      <Button type="submit" sx={{ mt: 3 }} variant="outlined">
        Verify
      </Button>

      {loading && <FullScreenLoader />}
    </Box>
  );
};

export default VerifyUser;
