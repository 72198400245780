import {
  Box,
  Menu,
  Button,
  styled,
  MenuItem,
  Typography,
  IconButton,
  Paper as MiUiPaper,
  SxProps,
  Theme,
  ButtonGroup,
  Tooltip,
  InputBase
} from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCart } from '../../store';
import { imageFallback } from '../../utils';
import { startCase, camelCase } from 'lodash';
import useRoomDining, { useMenuItem } from './room-dining-hook';
import { FullScreenLoader } from '../../components';
import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import useInfo from '../Dashboard/components/InfoCard';
import DineInDialog from '../../components/DineInDialog';
import { ALLERGEN_ICONS, CHILLI_ICONS } from '../../constant/services';
import useHotelService from '../HotelSer/hotel-ser-hooks';
import useHotelInfo from '../HotelInfo/hotel-info-hooks';
import { groupBy } from 'lodash';

const ALL_MENU = 'ALL_MENU';

interface HeaderLayoutProps {
  title: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export const HeaderLayout = ({ sx, title, children }: HeaderLayoutProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow:
          '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
        ...(sx ? sx : {})
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton disableRipple onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 1, fontSize: '13px' }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
interface HeaderProps {
  categories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
}
interface SubCategoryProps {
  subCategories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
}

const Header = (props: HeaderProps) => {
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, currentCategory, onClick } = props;
  const { propertyDetails } = useHotelInfo();
  return (
    <HeaderLayout title="In Room Dining">
      {propertyDetails?.getPropertyByDomain?.show_main_category &&
      categories.length > 1 ? (
        <>
          <Paper sx={{ backgroundColor: 'white', mr: -1.5 }}>
            <Button
              onClick={handleClick}
              sx={{ color: 'text.primary' }}
              endIcon={
                openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {startCase(camelCase(currentCategory))}
            </Button>
          </Paper>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {categories.map(category => (
              <MenuItem
                key={category}
                onClick={() => {
                  onClick && onClick(category);
                  handleClose();
                }}
                sx={{ backgroundColor: 'white' }}
              >
                {startCase(camelCase(category))}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        ''
      )}
    </HeaderLayout>
  );
};

const SubCategory = (props: SubCategoryProps) => {
const { hide_all_menu } = useHotelInfo();
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { subCategories, currentCategory, onClick } = props;

  return (
    <>
      <Paper sx={{ backgroundColor: 'white' }}>
        <Button
          onClick={handleClick}
          sx={{ color: 'text.primary' }}
          endIcon={
            openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          {startCase(camelCase(currentCategory))}
        </Button>
      </Paper>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
{!hide_all_menu && (
          <MenuItem
            key={ALL_MENU}
            onClick={() => {
              onClick && onClick(ALL_MENU);
              handleClose();
            }}
            sx={{ backgroundColor: 'white' }}
          >
            {startCase(camelCase(ALL_MENU))}
          </MenuItem>
        )}
        {subCategories?.map(category => (
          <MenuItem
            key={category}
            onClick={() => {
              onClick && onClick(category);
              handleClose();
            }}
            sx={{ backgroundColor: 'white' }}
          >
            {startCase(camelCase(category))}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { roomID } = useParams();
  const cart = useCart((state: any) => state.cart);
  const totalItems = cart.reduce(
    (prev: number, current: any) => prev + current.count,
    0
  );

  if (totalItems === 0) return null;

  return (
    <Box sx={{ p: 1 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(`/${roomID}/cart`)}
      >
        View Cart ({totalItems} Items)
      </Button>
    </Box>
  );
};

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  border: '1px solid #F0F0F0',
  boxShadow: 'none',
  borderRadius: theme.spacing(1.875)
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  paddingRight: '4px !important',
  paddingLeft: '4px !important',
  borderRadius: '5px',
  fontSize: '13px'
}));

interface LayoutProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

// Using position fixed because of chrome mobile's moving url bar's behavior
// https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile

export const Layout = ({ children, sx }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: '100%',
        minHeight: 'unset',
        flexDirection: 'column',
        backgroundColor: 'background.light',
        ...(sx ? sx : {})
      }}
    >
      {children}
    </Box>
  );
};

interface FoodItemCardProps {
  item: any;
  currentSubCategory: string;
}

export const FoodItemCard = ({
  item,
  currentSubCategory
}: FoodItemCardProps) => {
  const { currency } = useInfo();
  const {
    name,
    price,
    img,
    id,
    isVegan,
    desc,
    allergens,
    type,
    is_recommended,
    spice_level,
    extraOptions,
    selections
  } = item;
  const chili = (CHILLI_ICONS as any)[spice_level];

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  // const[Icon,setIcon]=React.useState(false)
  const cart = useCart((state: any) => state.cart);
  const addToCart = useCart((state: any) => state.addToCart);
  const removeFromCart = useCart((state: any) => state.removeFromCart);
  const { ALLERGEN_NAMES } = useMenuItem();

  const itemsInCart = React.useMemo(() => {
    const currentItems = cart.filter((item: any) => item.id === id);
    return currentItems.reduce((acc: number, curr: any) => acc + curr.count, 0);
  }, [cart, id]);

  const toggleDrawer = () => setOpen(state => !state);

  const addToCartWrapper = (id: string) => {
    if (item?.extraOptions?.length || item?.selections?.length) {
      toggleDrawer();
    } else {
      addToCart(id);
    }
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  // const handleIconOpen=()=>{
  //   setIcon(true)
  // }
  // const hanldleIconClose=()=>{
  //   setIcon(false)
  // }
  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
      {img ? (
        <Box
          src={img}
          alt={name}
          width={70}
          height={70}
          component="img"
          onError={imageFallback}
          style={{ borderRadius: '10px', objectFit: 'cover' }}
          onClick={handleOpen}
        />
      ) : (
        ''
      )}
      <Box sx={{ ml: 1.2, flex: 1 }}>
        <Box sx={{ display: 'flex' }}>
          {is_recommended === true && (
            <Tooltip
              enterTouchDelay={0}
              title="Chef's Recommendation"
              sx={{ mr: 0.5 }}
            >
              <Box
                component="img"
                onError={imageFallback}
                style={{
                  height: '20px',
                  width: '20x',
                  marginLeft: '5px',
                  marginTop: '-4px'
                }}
                src={`/assets/chef.svg`}
              />
            </Tooltip>
          )}
          <Typography variant="body2">
            {currentSubCategory === ALL_MENU ? `${name} (${type})` : name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '5px' }}>
          {desc && (
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
              onClick={handleOpen}
            >
              {desc.slice(0, 40)}...
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {allergens?.map((all: any, index: number) => {
            const icon = (ALLERGEN_ICONS as any)[all];
            const name = (ALLERGEN_NAMES as any)[all];
            return (
              <Tooltip enterTouchDelay={0} title={name || ''} key={index}>
                <Typography sx={{ pr: 1 }}>{icon}</Typography>
              </Tooltip>
            );
          })}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {isVegan === 'Vegan' && (
            <Tooltip enterTouchDelay={0} title="Vegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/veg.png`}
              />
            </Tooltip>
          )}
          {isVegan === 'NonVegan' && (
            <Tooltip enterTouchDelay={0} title="NonVegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/non-veg.png`}
              />
            </Tooltip>
          )}
          <Typography>{chili}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'flex-end'
        }}
      >
        <Typography variant="body2" mr="8px">
          {/* <Typography variant="body2" sx={{ marginRight: '-50px', marginTop: '-10px' }}> */}
          {currency} {price}
        </Typography>
        {!Boolean(itemsInCart) ? (
          <CustomButton
            size="small"
            variant="outlined"
            sx={{ alignSelf: 'center' }}
            onClick={() => addToCartWrapper(id)}
          >
            Add
          </CustomButton>
        ) : (
          <ButtonGroup
            size="small"
            variant="contained"
            sx={{
              border: 'none',
              color: '#ffffff',
              alignSelf: 'center',
              backgroundColor: 'primary.main'
            }}
          >
            <IconButton
              size="small"
              onClick={() => removeFromCart(id)}
              sx={{ color: '#ffffff' }}
            >
              <RemoveIcon fontSize="inherit" />
            </IconButton>
            <CustomButton
              size="small"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              sx={{ border: 'none !important' }}
            >
              {itemsInCart}
            </CustomButton>
            <IconButton
              size="small"
              onClick={() => addToCartWrapper(id)}
              sx={{ color: '#ffffff' }}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </ButtonGroup>
        )}
      </Box>

      {(item?.extraOptions?.length !== 0 || item?.selections) && (
        <ExtraItemDrawer
          open={open}
          currentItem={item}
          toggleDrawer={toggleDrawer}
        />
      )}
      <DineInDialog
        desc={desc}
        handleDialogClose={handleClose}
        image={img}
        open={openDialog}
        title={name || ''}
        price={price}
        icon={isVegan}
        currency={currency || ''}
        allergens={allergens}
        is_recommended={is_recommended}
        spice_level={spice_level}
        extras={extraOptions}
        selections={selections}
        item={item}
        addToCartWrapper={addToCartWrapper}
        itemsInCart={itemsInCart}
        removeFromCart={removeFromCart}
        id={id}
      />
    </Paper>
  );
};

// TODO: Extract All component above into a separate folder
const InRoomDining = () => {
  const [searchParams] = useSearchParams();
  const { diningType } = useHotelService();
    const category = searchParams.get('category');
  const [currentCategory, setCurrentCategory] = React.useState(category || '');
  const [search, setSearch] = React.useState<string>('');

  const { loading, foodMenu, categories, Sections, defaultCategory } =
    useRoomDining();
  const { background_color } = useInfo();

  const [currentSubCategory, setCurrentSubCategory] = React.useState('');

  const menuNames = React.useMemo(() => {
    return diningType === 'Category' ? [...categories] : [...Sections];
  }, [diningType, categories, Sections]);

  const subMenuNames = React.useMemo(() => {
    return diningType === 'Section' ? [...categories] : [...categories];
  }, [diningType, categories]);

  const foodCategories = menuNames;
  const [subCategories, setSubCategories] = React.useState<string[]>([
    ...subMenuNames
  ]);
  let DefaultSubCategory = defaultCategory(currentCategory);

  React.useMemo(() => {
    if (categories && DefaultSubCategory && currentCategory) {
      if (diningType === 'Category') {
        setCurrentSubCategory(category || '');
      } else {
        setCurrentSubCategory(DefaultSubCategory);
      }
    }
  }, [currentCategory, categories, DefaultSubCategory, diningType, category]);

  const hasRunForCategory = React.useRef(false);
  React.useEffect(() => {
    const updateSubMenuCategories = () => {
      if (currentCategory && foodMenu && subMenuNames) {
        const filteredMenu = foodMenu.find(
          (item: any) => item.name === currentCategory
        );

        if (filteredMenu) {
          const subMenuFilterList = subMenuNames.filter(subCategoryItem => {
            return filteredMenu.items?.find(
              (i: any) => i.type === subCategoryItem
            );
          });

          const shouldUpdateSubCategories =
            subCategories.length !== subMenuFilterList.length;

          if (shouldUpdateSubCategories) {
            const subMenuItems = subMenuNames.filter(subCategoryItem => {
              return filteredMenu.items?.find(
                (i: any) => i.type === subCategoryItem
              );
            });

          setSubCategories([...subMenuItems]);
          }
          if (diningType === 'Category' && !hasRunForCategory.current) {
            hasRunForCategory.current = true;
            setSubCategories([...subMenuFilterList]);
          }
        }
      }
    };

    updateSubMenuCategories();
  }, [
    currentCategory,
    subMenuNames,
    foodMenu,
    subCategories,
    diningType
  ]);

  const filteredMenu = React.useMemo(() => {
    if (currentSubCategory === ALL_MENU)
      return foodMenu?.filter((item: any) => item.name === currentCategory);

    let subFilterItems = foodMenu?.map((item: any) => {
      if (item.name === currentCategory) {
        let filterItem = item.items?.filter(
          (items: any) => items.type === currentSubCategory
        );
        return { ...item, items: filterItem };
      }
      return {};
    });
    return subFilterItems?.filter(value => {
      return value !== undefined && Object.keys(value).length > 0;
    });
  }, [currentCategory, currentSubCategory, foodMenu]);

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        categories={foodCategories}
        onClick={setCurrentCategory}
        currentCategory={currentCategory}
      />
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {/* Header */}

        {filteredMenu?.map((menu: any, foodMenuIndex: any) => {
          return (
            <React.Fragment key={menu.name}>
              <Box
                sx={{
                  paddingTop: '10px',
                  position: 'sticky',
                  top: '-3px',
                  mt: foodMenuIndex === 0 ? 1 : 3,
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  zIndex: 1,
                  backgroundColor: background_color
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                  {startCase(camelCase(menu.name))}
                </Typography>
                <SubCategory
                  subCategories={subCategories}
                  onClick={setCurrentSubCategory}
                  currentCategory={currentSubCategory}
                />
                {/* <Typography variant="body2">
                  {menu?.items?.length} Items
                </Typography> */}
              </Box>
              <InputBase
                placeholder="Search item..."
                onChange={(e: any) => setSearch(e.target.value)}
                sx={{
                  p: 1.5,
                  width: '100%',
                  borderRadius: 1.25,
                  backgroundColor: '#fff'
                }}
              />
              <Typography sx={{ mb: 1 }} variant="body2">
                {menu.description}
              </Typography>
              <Box>
                {Object.entries(groupBy(menu?.items, 'type'))
                  .sort(
                    (a, b) =>
                      categories.indexOf(a[0]) - categories.indexOf(b[0])
                  )
                  .map(([type, items]) => {
                    const filteredItems = items
                      ?.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
                      ?.filter((menu: any) =>
                        menu.name?.toLowerCase().includes(search?.toLowerCase())
                      );
                    return (
                      <React.Fragment key={type}>
                        <Typography
                          sx={{ fontWeight: 'bold', mt: 1, mb: 0 }}
                          variant="body2"
                        >
                          {filteredItems.length > 0 &&
                            startCase(camelCase(type))}
                        </Typography>{' '}
                        {/* Render the type as a heading */}
                        {items
                          ?.sort(
                            (a, b) => (a.sequence || 0) - (b.sequence || 0)
                          )
                          ?.filter((menu: any) =>
                            menu.name
                              ?.toLowerCase()
                              .includes(search?.toLowerCase())
                          )
                          ?.map(item => (
                            <FoodItemCard
                              key={item.id}
                              item={item}
                              currentSubCategory={currentSubCategory}
                            />
                          ))}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Footer />
    </Layout>
  );
};

export default InRoomDining;
