import {
  Box,
  Button,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import * as React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface DropDownProps {
  options: any[];
  sx?: SxProps<Theme>;
  selectedOption: any;
  onClick: (option: any) => void;
  extractId: (option: any) => string;
  extractLabel: (option: any) => string;
}

const DropDown = (props: DropDownProps) => {
  const { sx, options, selectedOption, extractLabel, extractId, onClick } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      <Button
        sx={{
          py: 1.5,
          px: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        fullWidth
        disableRipple
        disableFocusRipple
        size="large"
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{ ml: 2, fontWeight: 500, color: "primary.main" }}
          >
            {extractLabel(selectedOption)}
          </Typography>
        </Box>
        {openMenu ? (
          <KeyboardArrowUpIcon sx={{ color: "text.primary" }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: "text.primary" }} />
        )}
      </Button>
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={extractId(option)}
            onClick={() => {
              onClick(option);
              handleClose();
            }}
          >
            {extractLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { DropDown };
