import * as React from 'react';
import { useTheme } from '@emotion/react';
import { startCase } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  useGetTaskHistoryQuery,
  useRequestServicesMutation
} from '../../generated/graphql';
import { useServiceAlert } from '../../utils';
import { useServiceList } from '../Dashboard';
import { useAuth, useServices } from '../../store';
import Swal from 'sweetalert2';
import  { Dayjs } from 'dayjs';

const useHotelServices = () => {
  const theme: any = useTheme();
  const guest = useAuth((state: any) => state.guest);
  const [requestServices, { loading: requestingServices }] =
    useRequestServicesMutation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const [currentService, setCurrentService] = React.useState(service);
  const [openscheduleDialog, setOpenScheduleDialog] = React.useState(false);
  const currentServiceSimplified = startCase(currentService as string);

  const handleCloseSchedule = () => {
    setOpenScheduleDialog(false);
  };
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const formattedDate = selectedDate?.format('YYYY-MM-DD HH:mm:ss');
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const submitScheduleServices = async () => {
    const inputServices = services.map((service: any) => {
      return {
        property_id: guest?.property_id,
        quantity: service?.count,
        room_id: guest?.room_id,
        task_id: service?.id,
        notes: service?.notes,
        schedule_task: formattedDate
      };
    });

    try {
      const res = await requestServices({
        variables: {
          input: [...inputServices]
        }
      });
      if (res.data?.requestServices?.code === '200') {
        serviceAlert('Submitted Successfully');
      } else {
        serviceAlert(
          res.data?.requestServices?.message ||
            'Something went wrong! Please contact support'
        );
      }
      handleCloseSchedule();
    } catch (err) {
      console.log(err);
      serviceAlert(`Something went wrong! Please try again`);
    }
  };

  // Use open service list
  const { data: taskHistoryData, loading: loadingTaskHistory } =
    useGetTaskHistoryQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
      variables: {
        guestID: guest?.guest_id,
        propertyID: guest?.property_id,
        roomID: guest?.room_id
      },
      skip: !guest?.guest_id
    });

  const {
    servicesList,
    groupedServices,
    loading: loadingServiceList
  } = useServiceList();
  const currentServiceList =
    groupedServices[currentServiceSimplified as string];

  // Service Request Flow
  const services = useServices((state: any) => Object.values(state.services));
  const itemsInCart = React.useMemo(
    () =>
      services.reduce((prev: number, current: any) => prev + current?.count, 0),
    [services]
  );

  const cancelServices = useServices((state: any) => state.cancelServices);
  const addToService = useServices((state: any) => state.addToService);
  const removeFromService = useServices(
    (state: any) => state.removeFromService
  );
  const serviceAlert = useServiceAlert(() => {
    navigate(-1);
    cancelServices();
  });
  // Function to show the SweetAlert confirmation dialog
  const showConfirmationDialog = async () => {
    return Swal.fire({
      title: 'Confirm Request',
      text: 'Do you want to schedule your request?',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#808080',
      cancelButtonColor: '#7066e0'
    });
  };
  const submitServices = async () => {
    const result = await showConfirmationDialog();

    if (result.isConfirmed) {
      setOpenScheduleDialog(true);
    } else {
      const inputServices = services.map((service: any) => {
        return {
          property_id: guest?.property_id,
          quantity: service?.count,
          room_id: guest?.room_id,
          task_id: service?.id,
          notes: service?.notes,
          schedule_task: ''
        };
      });

      try {
        const res = await requestServices({
          variables: {
            input: [...inputServices]
          }
        });

        if (res.data?.requestServices?.code === '200') {
          serviceAlert('Submitted Successfully');
        } else {
          serviceAlert(
            res.data?.requestServices?.message ||
              'Something went wrong! Please contact support'
          );
        }
      } catch (err) {
        console.log(err);
        serviceAlert(`Something went wrong! Please try again`);
      }
    }
  };

  // Input Search
  const [searchInput, setSearchInput] = React.useState('');

  const filteredServiceList = React.useMemo(() => {
    return currentServiceList?.filter(({ task }: any) =>
      task.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [currentServiceList, searchInput]);

  const taskHistoryMap = React.useMemo(() => {
    const taskHistoryMap: any = {};
    taskHistoryData?.getTaskHistory?.forEach((task: any) => {
      taskHistoryMap[task?.task_list] = task;
    });
    return taskHistoryMap;
  }, [taskHistoryData?.getTaskHistory]);

  return {
    theme,
    navigate,
    itemsInCart,
    searchInput,
    servicesList,
    addToService,
    cancelServices,
    submitServices,
    setSearchInput,
    taskHistoryMap,
    removeFromService,
    setCurrentService,
    currentServiceSimplified,
    currentServiceList: filteredServiceList,
    loading: loadingServiceList || requestingServices || loadingTaskHistory,
    openscheduleDialog,
    handleCloseSchedule,
    handleDateChange,
    selectedDate,
    submitScheduleServices
  };
};

export default useHotelServices;
