import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ChatIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <path
        d="M6.90407 2.82247C8.52439 1.85191 10.3776 1.33903 12.2664 1.33844C13.8378 1.33751 15.3892 1.69129 16.8049 2.3734C18.2206 3.05552 19.4641 4.04837 20.4426 5.27795C21.4212 6.50753 22.1096 7.94213 22.4566 9.4748C22.8035 11.0075 22.8 12.5987 22.4464 14.1298C22.0928 15.661 21.3981 17.0926 20.4142 18.3179C19.4303 19.5432 18.1825 20.5306 16.7638 21.2065C15.3452 21.8824 13.7923 22.2295 12.2208 22.2217C10.9093 22.2152 9.61305 21.9617 8.39932 21.4769L2.1061 22.5248C1.73413 22.5867 1.43008 22.2303 1.54988 21.8727L3.19861 16.9517C2.38536 15.5253 1.91824 13.9239 1.83978 12.2771C1.74988 10.3905 2.1734 8.51479 3.06522 6.84984C3.95704 5.18489 5.28376 3.79302 6.90407 2.82247Z"
        fill={theme.palette.primary.light}
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5713 12.4121C17.3437 12.4121 17.1592 12.2276 17.1592 12C17.1592 11.7724 17.3437 11.5879 17.5713 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5713 12.4121C17.7989 12.4121 17.9834 12.2276 17.9834 12C17.9834 11.7724 17.7989 11.5879 17.5713 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4284 12.4121C12.2009 12.4121 12.0164 12.2276 12.0164 12C12.0164 11.7724 12.2009 11.5879 12.4284 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4285 12.4121C12.6561 12.4121 12.8406 12.2276 12.8406 12C12.8406 11.7724 12.6561 11.5879 12.4285 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.28562 12.4121C7.05803 12.4121 6.87354 12.2276 6.87354 12C6.87354 11.7724 7.05803 11.5879 7.28562 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.28567 12.4121C7.51326 12.4121 7.69775 12.2276 7.69775 12C7.69775 11.7724 7.51326 11.5879 7.28567 11.5879"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export { ChatIcon };
