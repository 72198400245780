import { Outlet } from "react-router-dom";
import { Box, SxProps, Theme } from "@mui/material";

interface LayoutProps {
  sx?: SxProps<Theme>;
}

const Layout = ({ sx }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        ...(sx ? sx : {}),
      }}
    >
      <Outlet />
    </Box>
  );
};

export { Layout };
