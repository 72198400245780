import * as React from 'react';
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
  useLocation
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { Cache } from 'aws-amplify';

import Chat from './Chat';
import Cart from './Mycart';
import Home from './Home';
import ErrorPopup from './Popup';
import HotelSer from './HotelSer';
import { useAuth } from '../store';
import HotelInfo from './HotelInfo';
import VerifyUser from './VerifyUser';
import HotelRooms from './HotelRooms';
import meQuery from '../utils/meQuery';
import { Dashboard } from './Dashboard';
import { useDomain } from '../utils';
import { Promotions } from './Promotions';
import InRoomDining from './InRoomDining';
import HotelServices from './HotelServices';
import NotVerified from './Dashboard/NotVerified';
import { FullScreenLoader, Layout } from '../components';
import OrderHistory from './OrderHistory';
import ServiceHistory from './ServiceHistory';
import DiningMenu from './Dining';
import Itineraries from './Itineraries';

const useGuestInfo = () => {
  const [getGuest] = useLazyQuery(meQuery);
  const [loading, setLoading] = React.useState(true);

  const domainId = useDomain();
  const setGuest = useAuth((state: any) => state.setGuest);

  React.useEffect(() => {
    async function getGuestInfo() {
      setLoading(true);
      const url = window.location.href;
      const roomID = url.substring(url.lastIndexOf('/') + 1);

      if (domainId && roomID) {
        try {
          const { data } = await getGuest({
            query: meQuery,
            variables: {
              propertyID: domainId,
              roomID: roomID
            }
          });

          if (data.getGuest) {
            setGuest({ ...data.getGuest, room_id: roomID });
          }
          const getUser = Cache.getItem('user');
          setGuest(getUser);
        } catch (error) {}
      }
      setLoading(false);
    }

    getGuestInfo();
  }, [setGuest, getGuest, setLoading, domainId]);

  return { loading };
};

type RoutesProps = {
  children: JSX.Element;
};

function AuthRoutes({ children }: RoutesProps) {
  // Shifting to non reactive state here:
  // Reason this component gets automatically called because of the state subscription
  // We just want to read it one time and validate the user
  // const guest = useAuth((state: any) => state.guest);
  const guest = useAuth.getState().guest;

  const authCode = parseInt(guest.code);
  const message = guest?.message;

  if (authCode !== 200) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />;
    return <ErrorPopup />;
  }

  //for guest required authentication
  if (authCode === 200 && message === 'Authentication Required') {
    return <ErrorPopup />;
  }

  return children;
}

function NoAuthRoutes({ children }: RoutesProps) {
  // Shifting to non reactive state here:
  // Reason this component gets automatically called because of the state subscription
  // We just want to read it one time and validate the user
  // const guest = useAuth((state: any) => state.guest);
  const guest = useAuth.getState().guest;
  const isAuthenticated = !isEmpty(guest?.guest_id);

  const location = useLocation();

  if (!isAuthenticated) return children;
  const from =
    (location.state as any)?.from?.pathname ||
    `/${guest?.room || guest?.room_id}`;

  return <Navigate to={from} />;
}

const AppRouter = () => {
  // TODO:
  // Fetching user info only once!
  // Need to check the edge case
  const { loading } = useGuestInfo();

  // TODO:
  // Add logic here to add auth i.e. navigate to login/auth page
  if (loading) return <FullScreenLoader />;

  return (
    <Router>
      <Routes>
        {/* Putting /error route first otherwise all the routes will match to /:roomID */}

        <Route
          path="/"
          element={
            <NoAuthRoutes>
              <Layout />
            </NoAuthRoutes>
          }
        >
          <Route index element={<Home />} />
          <Route path="/verify-user" element={<VerifyUser />} />
          <Route path="/not-verified" element={<NotVerified />} />
        </Route>

        <Route
          path="/:roomID"
          element={
            <AuthRoutes>
              <Layout />
            </AuthRoutes>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="cart" element={<Cart />} />
          {/* <Route path="chat" element={<Chat />} /> */}
          <Route path="services" element={<HotelServices />} />
          <Route path="order-history" element={<OrderHistory />} />
          <Route path="in-room-dining" element={<InRoomDining />} />
          <Route path="service-information/:id" element={<HotelSer />} />
          <Route path="service-history" element={<ServiceHistory />} />
          <Route path="itineraries" element={<Itineraries />} />
        </Route>
        <Route path="promotions" element={<Promotions />} />
        <Route path="chat" element={<Chat />} />
        <Route path="hotel-rooms" element={<HotelRooms />} />
        <Route path="dining" element={<DiningMenu />} />
        <Route path="hotel-information" element={<HotelInfo />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
