import {
  Box,
  SxProps,
  Theme,
  Typography,
  Stack
} from '@mui/material';

import { CardPaper } from './CardPaper';
import { Link } from 'react-router-dom';

interface ItinerariesProps {
  sx?: SxProps<Theme>;
}

function Itineraries({ sx }: ItinerariesProps) {
  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 1, ...(sx ? sx : {}) }}>
      <Link to="itineraries" style={{ textDecoration: 'none', flex: 1 }}>
        <CardPaper sx={{ mr: 0.5, px: 3, py: 2.5 }}>
          <Stack direction="column">
            <Typography
              align="center"
              variant="body2"
              sx={{ fontWeight: 600, color: 'primary.main' }}
            >
              View Itineraries
            </Typography>
          </Stack>
        </CardPaper>
      </Link>
    </Box>
  );
}

export { Itineraries };
