import * as React from 'react';
import { format } from 'date-fns';

import { useAuth } from '../../store';
import { FullScreenLoader } from '../../components';
import { CardPaper } from '../Dashboard/components';
import { HeaderLayout, Layout } from '../InRoomDining';
import { Cart, Status, useMyOrdersQuery } from '../../generated/graphql';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import useInfo from '../Dashboard/components/InfoCard';

function OrderHistory() {
  const { currency } = useInfo();
  const guest = useAuth((state: any) => state.guest);
  const { data: orders, loading: loadingOrders } = useMyOrdersQuery({
    variables: {
      guestID: guest?.guest_id,
      propertyID: guest?.property_id
    },
    skip: !guest?.guest_id
  });

  const { orderList, total } = React.useMemo(() => {
    if (!orders?.getGuestOrders?.length) {
      return { orderList: [], total: 0 };
    }

    const orderList = orders?.getGuestOrders
      ?.slice()
      .sort(
        (a: any, b: any) =>
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
      );

    const total = orderList.reduce((acc: any, curr: any) => {
      return acc + curr.total;
    }, 0);

    return { orderList, total };
  }, [orders?.getGuestOrders]);

  if (loadingOrders) return <FullScreenLoader />;

  return (
    <Layout>
      <HeaderLayout title="Order History" />

      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: 'auto',
          textAlign: 'left',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 600 }}>
            Total Spent:
          </Typography>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 600 }}>
            {currency} {total.toFixed(2)}
          </Typography>
        </Stack>
        {orderList?.map((order: any) => (
          <CardPaper key={order?.id} sx={{ p: 2, mt: 3 }}>
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {format(new Date(order?.created_at), 'dd-MM-yyyy hh:mm a')}
              </Typography>
              {order?.status === Status.Pending && (
                <Chip
                  label="Pending"
                  size="small"
                  color="warning"
                  variant="filled"
                />
              )}
              {order?.status === Status.InProgress && (
                <Chip
                  label="InProgress"
                  size="small"
                  color="primary"
                  variant="filled"
                />
              )}
              {order?.status === Status.Delivered && (
                <Chip
                  label="Completed"
                  size="small"
                  color="success"
                  variant="filled"
                />
              )}
              {order?.status === Status.Cancelled && (
                <Chip
                  label="Cancelled"
                  size="small"
                  color="error"
                  variant="filled"
                />
              )}
            </Box>
            {order?.cart_items?.map((cartItem: Cart) => (
              <Box
                sx={{
                  mb: 1,
                  mx: 1,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                key={cartItem?.dineInItem_id}
              >
                <Typography variant="body2" sx={{ flex: 3 }}>
                  {cartItem?.dineInItem_name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, textAlign: 'center' }}
                >
                  x {cartItem?.quantity}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, textAlign: 'right' }}
                >
                  {currency} {cartItem?.dineInItem_price * cartItem?.quantity}
                </Typography>
              </Box>
            ))}

            <Divider />
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="body2">Total</Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {currency} {order?.total}
              </Typography>
            </Box>
          </CardPaper>
        ))}
      </Box>
    </Layout>
  );
}

export default OrderHistory;
