import * as React from 'react';
import { isPast, isFuture } from 'date-fns';
import { styled } from '@mui/material/styles';

import { useAuth } from '../../store';
import { FullScreenLoader } from '../../components';
import { HeaderLayout, Layout } from '../InRoomDining';
import { useGetItineraryQuery } from '../../generated/graphql';
import {
  Box,
  Stack,
  Typography,
  Paper,
  Card,
  CardContent,
  Grid
} from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';

const Item = styled(Paper)(({ theme }) => ({
  background: 'none',
  boxShadow: 'none',
  transition: 'none',
  fontSize: '12px',
  lineHeight: '3.5rem',
  marginBottom: '5px'
}));

function Itineraries() {
  const guest = useAuth((state: any) => state.guest);

  const { data: itineraries, loading: loadingItineraries } =
    useGetItineraryQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
      variables: {
        propertyID: guest?.property_id,
        guestID: guest?.guest_id,
        roomID: guest?.room
      },
      skip: !guest?.guest_id
    });

  const dateWiseArray: { [key: string]: any } = {};

  if (
    itineraries &&
    itineraries.getItinerary &&
    itineraries.getItinerary.length
  ) {
    itineraries.getItinerary
      .slice()
      .sort(
        (objA: any, objB: any) =>
          Number(
            new Date(objA.start_date + ' ' + objA?.time?.replace('.', ':'))
          ) -
          Number(
            new Date(objB.start_date + ' ' + objB?.time?.replace('.', ':'))
          )
      )
      .forEach(itinerary => {
        if (itinerary) {
          const startDate = itinerary?.start_date;

          if (!dateWiseArray[startDate]) {
            dateWiseArray[startDate] = [];
          }

          dateWiseArray[startDate].push(itinerary);
        }
      });
  }
  const [expand, setExpand] = React.useState<boolean>(false);
  function convertUtcToLocalTime(time:string) {
    if (
      !time ||
      isNaN(parseInt(time)) ||
      isNaN(parseInt(time))
    ) {
      return time;
    }
    const [hours, minutes] = time.split(":");
    const utcDate = new Date();
    utcDate.setUTCHours(parseInt(hours));
    utcDate.setUTCMinutes(parseInt(minutes));
    const localTimeString = utcDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return localTimeString;
  }

  if (loadingItineraries) return <FullScreenLoader />;

  return (
    <Layout>
      <HeaderLayout title="Itineraries" />

      <Timeline>
        {Object.keys(dateWiseArray).map(date => (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0', flex: 0, p: 0 }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ fontSize: '15px', m: 0, p: '2px' }}>
                <FastfoodIcon fontSize="inherit" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '5px', px: '5px' }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'inherit',
                  display: 'flex',
                  alignItems: 'center'
                }}
                component="span"
              >
                <b>{date}</b>
              </Typography>
              {dateWiseArray[date].map((itinerary: any) => {
                return (
                  <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Grid container>
                      <Grid xs={3}>
                        <Item>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              lineHeight: 'inherit',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            component="span"
                          >
                            <b>{convertUtcToLocalTime(itinerary?.time)}</b>
                          </Typography>
                        </Item>
                      </Grid>
                      <Grid xs={9}>
                        <Item sx={{ width: '100%' }}>
                          <Card
                            sx={{
                              px: '5px',
                              backgroundColor: 'white',
                              borderRadius: '16px',
                              border: isPast(new Date(itinerary.start_date))
                                ? 'orange 1px solid'
                                : isFuture(new Date(itinerary.start_date))
                                ? 'lightgreen 1px solid'
                                : 'lightgreen 1px solid'
                            }}
                          >
                            <CardContent
                              sx={{
                                p: '2px',
                                pb: '2px !important',
                                borderRadius: '25px'
                              }}
                              onClick={() => setExpand(!expand)}
                            >
                              <Item>
                                <Typography
                                  sx={{
                                    fontSize: '15px',
                                    color: isPast(
                                      new Date(itinerary.start_date)
                                    )
                                      ? 'orange'
                                      : isFuture(new Date(itinerary.start_date))
                                      ? 'green'
                                      : ''
                                  }}
                                >
                                  <b>{itinerary?.name}</b>
                                </Typography>
                              </Item>
                              <Item>
                                <Stack direction="row" spacing={2}>
                                  <Item>
                                    <Typography
                                      sx={{
                                        fontSize: 'inherit',
                                        color: isPast(
                                          new Date(itinerary.start_date)
                                        )
                                          ? 'orange'
                                          : isFuture(
                                              new Date(itinerary.start_date)
                                            )
                                          ? 'green'
                                          : ''
                                      }}
                                    >
                                      Duration : {itinerary?.duration} min
                                    </Typography>
                                  </Item>
                                  <Item>
                                    <Typography
                                      sx={{
                                        fontSize: '12px',
                                        color: isPast(
                                          new Date(itinerary.start_date)
                                        )
                                          ? 'orange'
                                          : isFuture(
                                              new Date(itinerary.start_date)
                                            )
                                          ? 'green'
                                          : ''
                                      }}
                                    >
                                      Venue : {itinerary?.venue}
                                    </Typography>
                                  </Item>
                                </Stack>
                              </Item>
                            </CardContent>
                            {/* <Collapse in={expand}>
                          <Typography>
                            {' '}
                            Hands-on cooking class to learn local cuisine.{' '}
                            "culinary", "education"
                          </Typography>
                        </Collapse> */}
                          </Card>
                        </Item>
                      </Grid>
                    </Grid>
                  </Stack>
                );
              })}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: 'auto',
          textAlign: 'left',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {/* TODO Add your code here */}
      </Box>
    </Layout>
  );
}

export default Itineraries;
