import * as React from 'react';
import {
  useDineInListQuery,
  useGetAllDineInSectionsQuery,
  useGetAllergensQuery,
  useGetDineInSectionsQuery,
  useGetGuestDineInCategoriesQuery
} from '../../generated/graphql';
import { groupBy } from 'lodash';
import { useAuth } from '../../store';
import useHotelService from '../HotelSer/hotel-ser-hooks';

export const useMenuItem = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data, loading } = useDineInListQuery({
    variables: {
      propertyID: guest?.property_id
    }
  });
  const { data: Categories, loading: CategoriesLoading } =
    useGetGuestDineInCategoriesQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: guest?.property_id
      }
    });

  const { data: Sections, loading: SectionLoading } = useGetDineInSectionsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        propertyID: guest?.property_id
      }
    }
  );
  const { data: AllSections, loading: AllSectionLoading } = useGetAllDineInSectionsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        propertyID: guest?.property_id
      }
    }
  );
  const { data: Allergens } = useGetAllergensQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: guest?.property_id
    }
  });

  const allergenName: { [key: string]: string } = {};
  Allergens?.getAllergens?.forEach(
    allergen => (allergenName[allergen?.icon || ''] = allergen?.name || '')
  );

  return {
    data,
    loading: loading || CategoriesLoading || SectionLoading || AllSectionLoading,
    Categories: Categories?.getGuestDineInCategories,
    Sections: Sections?.getDineInSections,
    AllSections:AllSections?.getAllDineInSections,
    ALLERGEN_NAMES: allergenName
  };
};

const useRoomDining = () => {
  const { data, loading, Categories, Sections } = useMenuItem();
  let sortedSection = [...(Sections || [])]?.sort(
    (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
  );
  const { diningType } = useHotelService();
  const [foodMenu, setFoodMenu] = React.useState<any[]>([]);

  React.useMemo(() => {
    if (!data) return { categories: [], foodMenu: [] };
    let foodMenuItems = [];
    if (diningType === 'Category') {
      const groupedMenu = groupBy(
        data?.getGuestEdibles,
        (data: any) => data.type
      );
      foodMenuItems =
        Categories?.map(category => {
          return {
            description: category?.desc,
            name: category?.name,
            items: groupedMenu[category?.name || '']
          };
        }) || [];
    } else {
      let iterable: { [key: string]: any } = {};
      let edibleLength = data?.getGuestEdibles?.length || 0;
      let SectionLength = Sections?.length || 0;
      for (let i = 0; i < edibleLength; i++) {
        for (let j = 0; j < SectionLength; j++) {
          let SectionName = Sections?.[j]?.name;
          if (
            data?.getGuestEdibles?.[i]?.section?.includes(SectionName || '')
          ) {
            if (SectionName) {
              if (iterable[SectionName]) {
                iterable[SectionName].push(data?.getGuestEdibles[i]);
              } else {
                iterable[SectionName] = [data?.getGuestEdibles[i]];
              }
            }
          }
        }
      }
      foodMenuItems =
        Sections?.map(section => {
          return {
            description: section?.desc,
            name: section?.name,
            items: iterable[section?.name || '']
          };
        }) || [];
    }
    setFoodMenu(foodMenuItems);
  }, [data, Categories, Sections, diningType]);

  function findDefaultCategory(category: any) {
    for (let i = 0; i < sortedSection?.length; i++) {
      if (
        sortedSection[i]?.name === category &&
        sortedSection[i]?.default_category
      ) {
        return sortedSection[i]?.default_category;
      }
    }
    return [...(Categories || [])]?.sort(
      (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
    )[0]?.name;
  }

  const categoryList = React.useMemo(() => {
    return (
      [...(Categories || [])]?.sort(
        (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
      )?.map(section => section?.name as string) || ([] as string[])
    );
  }, [Categories]);

  const sectionList = React.useMemo(() => {
    return (
      sortedSection?.map(section => section?.name as string) || ([] as string[])
    );
  }, [sortedSection]);

  return {
    loading,
    Sections: sectionList,
    categories: categoryList,
    foodMenu: [...foodMenu],
    defaultCategory: findDefaultCategory
  };
};

export default useRoomDining;
