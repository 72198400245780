export const DRAWER_WIDTH = 240;

export const SYSTEM_FONTS = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const APP_FONT = "'Lato', sans-serif;";

export const SWR_CONFIG = {
  // refreshInterval: 2000,
  shouldRetryOnError: false,
};
